<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal" v-if="type">{{ $t('minus_balance') }}</div>
          <div class="title-new-modal" v-else>{{ $t('add_balance') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <b-tabs type="is-boxed">
            <b-tab-item v-for="(item, idx) in object.paymentGroups" :key="idx">
              <template #header>
                <span class="tag is-green-success width-110 mx-10 rounded-10">{{ item.currencyTo }}</span>
              </template>
              <div class="columns">
                <div class="column title">{{ $t('name') }}</div>
                <div class="column title"><span v-if="object.userType !== userTypes.DEALER">{{
                    $t('payment_groups')
                  }}</span><span v-else>{{ $t('currency') }}</span></div>
                <div class="column title is-4">{{ $t('balances') }}</div>
              </div>
              <div class="columns">
                <div class="column name">{{ object.name }}</div>
                <div class="column group"><span v-if="object.userType !== userTypes.DEALER">{{ item.groupName }}-</span>{{
                    item.currencyTo
                  }}
                </div>
                <div class="column is-4">
                  <b-field>
                    <b-input v-model="balances[item.currencyTo]" type="number"></b-input>
                  </b-field>
                </div>
              </div>
              <section>
                <div>
                  <b-field :label="$t('description')">
                    <b-input v-model="description[item.currencyTo]" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </section>
            </b-tab-item>
          </b-tabs>

          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {DEPOSIT_TYPE} from "@/enum/depositType";
import {USER_TYPE} from "@/enum/userType";

export default {
  name: "modalAddBalance",
  props: ['object', 'type', 'customer_type'],
  data() {
    return {
      description: {},
      currency: null,
      amount: null,
      userTypes: USER_TYPE,
      customersTypes: DEPOSIT_TYPE,
      balances: {},
      payments: [],
      paymentsGroups: [],
    }
  },
  methods: {
    ...mapActions({
      saveBalance: 'DEPOSIT_BALANCE_CUSTOMER'
    }),
    save() {
      this.object.paymentGroups.forEach(value => {
        this.payments.push({
          paymentGroupId: value.id,
          description: this.description[value.currencyTo],
          currency: value.currencyTo,
          amount: Number(this.balances[value.currencyTo])
        })
      })
      let request = {
        id: this.object.id,
        customer_type: this.customer_type,
        type: this.type,
        balances: this.payments
      }
      this.saveBalance(request)
          .then(() => {
            this.close()
          })

    },
    close() {
      this.$emit('close')
      this.$emit('reload')
      this.payments = []
      this.balances = {}
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.title {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #666BAA;
}

.group {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #289BA4;
}
</style>